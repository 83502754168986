







































import mixins from 'vue-typed-mixins'
import Section from '@/calendesk/sections/section/mixins/Section'
import DefaultButton from '@/calendesk/sections/section/components/DefaultButton.vue'

export default mixins(Section).extend({
  name: 'Background2',
  components: { DefaultButton }
})
